import axios from "axios/index";

export const list = (nickName, phone, plateNumber, page, pageSize) => {
  return axios.get("/member/user/list", {
    params: {
      nickName,
      phone,
      plateNumber,
      page,
      pageSize,
    },
  });
};

export const detail = useId => {
  return axios.get(`/member/user/${useId}/detail`);
};

export const oweRecords = useId => {
  return axios.get(`/member/user/${useId}/oweRecords`);
};
