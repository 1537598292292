<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="会员详细资料"
    :width="950"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button @click="visible = false">取消</a-button>
    </template>

    <div style="padding: 4px 0">
      <a-descriptions layout="vertical" bordered :column="5">
        <a-descriptions-item label="手机号码">{{
          memberVo.phone
        }}</a-descriptions-item>
        <a-descriptions-item label="用户昵称">{{
          memberVo.nickName
        }}</a-descriptions-item>
        <a-descriptions-item label="OpenId">{{
          memberVo.wcOpenid
        }}</a-descriptions-item>
        <a-descriptions-item label="注册时间">{{
          memberVo.createTime
        }}</a-descriptions-item>
        <a-descriptions-item label="待支付停车总金额（元）">{{
          getoweAmount(memberVo.oweAmount)
        }}</a-descriptions-item>
      </a-descriptions>
    </div>

    <div style="padding: 4px 0">
      <a-tabs type="card" v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="绑定车辆信息"> </a-tab-pane>
        <a-tab-pane key="2" tab="会员未支付停车记录"> </a-tab-pane>
      </a-tabs>
      <div v-if="activeKey == 1">
        <a-row
          type="flex"
          justify="start"
          align="middle"
          class="row_item_title"
        >
          <a-col :span="4" :order="1" class="col_item_title">序号</a-col>
          <a-col :span="8" :order="2" class="col_item_title">车牌号</a-col>
          <a-col :span="12" :order="3" class="col_item_title">绑定时间</a-col>
        </a-row>

        <div
          v-for="(item, index) in items.userPlateNumberResVoList"
          :key="index"
        >
          <a-row
            type="flex"
            justify="center"
            align="middle"
            class="row_item_content"
          >
            <a-col :span="4" :order="1" class="col_item_content">{{
              index + 1
            }}</a-col>
            <a-col :span="8" :order="2" class="col_item_content">{{
              item.plateNumber
            }}</a-col>
            <a-col :span="12" :order="3" class="col_item_content">{{
              item.createTime
            }}</a-col>
          </a-row>
        </div>
      </div>
      <div v-if="activeKey == 2">
        <a-table
          row-key="id"
          table-layout="fixed"
          :data-source="items2.userOweRecords"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          bordered
        >
        <template #shouldPayAmount="{ text }">{{ getoweAmount(text) }}</template>
        <template #oweAmount="{ text }">{{ getoweAmount(text) }}</template>

        </a-table>
        <a-pagination
          v-model:current="page"
          v-model:page-size="pageSize"
          :total="total"
          :show-total="total => `${total}条数据`"
          @change="currentChange"
        >
        </a-pagination>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { ref, reactive, onMounted, toRefs } from "vue";
import useMessage from "@/hooks/useMessage";
import { detail, oweRecords } from "@/api/member/listApi";
import useList from "../../../../hooks/member/useList";
import usePagination from "@/hooks/usePagination";

export default {
  name: "Detail",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { memberVo,getoweAmount} = useList();
    const items = reactive({ userPlateNumberResVoList: [] });
    const { showErr } = useMessage();
    const visible = ref(true);
    const activeKey = ref("1");
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);
    const loading = ref(false);
    const items2 = reactive({ userOweRecords: [] });
    const total = ref(0);
    const columns = [
      {
        title: "序号",
        align: "center",
        width: "80px",
        customRender: ({ index }) => {
          return index + 1;
        },
      },
      {
          title: "车场名称",
          align: "center",
          dataIndex: "parkingLotName",
      },
      {
        title: "车牌号",
        align: "center",
        dataIndex: "plateNumber",
      },
      {
        title: "进场时间",
        align: "center",
        dataIndex: "inTime",
      },
      {
        title: "离场时间",
        align: "center",
        dataIndex: "outTime",
      },
      {
        title: "应付费用（元）",
        align: "center",
        dataIndex: "shouldPayAmount",
        slots: { customRender: "shouldPayAmount" },
      },
      {
          title: "欠费金额（元）",
          align: "center",
          dataIndex: "oweAmount",
          slots: { customRender: "oweAmount" },
      }
    ];
    const loadData = async (page, pageSize) => {
      loading.value = true;
      try {
        let r3 = await oweRecords(props.id, page, pageSize);
        items2.userOweRecords = r3.data;
        total.value = r3.total;
        loading.value = false;
      } catch (e) {
        loading.value = false;
        await showErr(e);
      }
    };
    const money = m => {
        if (typeof m !== "undefined") {
            return (m / 100).toFixed(2);
        }
        return "";
    };
    onMounted(async () => {
      try {
        let r2 = await detail(props.id);
        memberVo.phone = r2.data.phone;
        memberVo.nickName = r2.data.nickName;
        memberVo.wcOpenid = r2.data.wcOpenid;
        memberVo.createTime = r2.data.createTime;
        memberVo.oweAmount = r2.data.oweAmount;
        items.userPlateNumberResVoList = r2.data.plateNumbers;
      } catch (e) {
        await showErr(e);
      }
      loadData(page.value, pageSize.value);
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      memberVo,
      activeKey,
      items,
      page,
      pageSize,
      total,
      currentChange,
      doQuery,
      doRefresh,
      columns,
      loading,
      items2,
      loadData,
      getoweAmount,
    };
  },
};
</script>

<style scoped>
.row_item_title {
  background-color: #fafafa;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-right: none;
  height: 35px;
  text-align: center;
}
.row_item_title .col_item_title {
  height: 35px;
  padding: 6px;
  border-right: 1px solid #dddddd;
  text-align: center;
}

.row_item_content {
  border: 1px solid #dddddd;
  border-right: none;
  border-top: none;
  height: 35px;
  text-align: center;
}
.row_item_content .col_item_content {
  height: 35px;
  padding: 6px;
  border-right: 1px solid #dddddd;
  text-align: center;
}
</style>
