import { reactive, ref } from "vue";
import { list } from "@/api/member/listApi";
import useMessage from "@/hooks/useMessage";
const useList = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);

  const memberVo = reactive({
    nickName: "",
    phone: "",
    plateNumber: "",
    wcOpenid: "",
    oweAmount: "",
    createTime: "",
    subscribeState:"",
  });
    const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "手机号码",
      align: "center",
      dataIndex: "phone",
    },
    {
      title: "用户昵称",
      align: "center",
      dataIndex: "nickName",
    },
    {
      title: "车牌号",
      align: "center",
      dataIndex: "plateNumbers",
      slots: { customRender: "plateNumbers" },
    },
    {
      title: "是否关注公众号",
      align: "center",
      dataIndex: "subscribeState",
      slots: { customRender: "subscribeState" },
    },
    {
      title: "待支付停车金额（元）",
      align: "center",
      dataIndex: "oweAmount",
      slots: { customRender: "oweAmount" },
    },
    {
      title: "注册时间",
      align: "center",
      dataIndex: "createTime",
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list(
        params.nickName,
        params.phone,
        params.plateNumber,
        page,
        pageSize,
      );
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    nickName: "",
    phone: "",
    plateNumber: "",
  });
  const getoweAmount = oweAmount => {
        if ( oweAmount !== "undefined") {
            return (oweAmount / 100).toFixed(2);
        }
        return "";
    };
  return {
    loading,
    params,
    dataList,
    total,
    columns,
    loadData,
    memberVo,
    getoweAmount,
  };
};
export default useList;
